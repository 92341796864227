import { Icons } from '@fagforbundet/svgs'
import joinIcons from './lib/icons'
import Rollbar from 'rollbar'
import { Globals } from './Globals'
import Vue from 'vue'
import App from './App.vue'
import { i18n } from './i18n'
import './scss/application.scss'
import VueCookieBot from '@ambitiondev/vue-cookiebot-plugin'
import { CookieBot } from '@ambitiondev/vue-cookiebot-plugin/dist/types/cookiebot'

Vue.use(VueCookieBot, {
  cookieBotID: 'dd0b9572-b52d-42a0-a35d-d62664a99afe'
})

const icons: Record<string, unknown> = joinIcons
Object.keys(icons).forEach((name: string) => {
  Icons.add(name, icons[name])
})

// Rollbar
declare module 'vue/types/vue' {
  interface Vue {
    $rollbar: Rollbar;
    $cookiebot: CookieBot;
  }
}

Vue.prototype.$rollbar = new Rollbar({
  accessToken: Globals.ROLLBAR_ACCESS_TOKEN,
  captureUncaught: true,
  captureUnhandledRejections: true
})

Vue.config.errorHandler = (err: Error, vm: Vue, info: string) => {
  vm.$rollbar.error(err, info)
  throw err
}

for (const [key, value] of Object.entries(joinIcons)) {
  Icons.add(key, value)
}

Vue.config.productionTip = false

new Vue({
  i18n,
  render: h => h(App)
}).$mount('#app')
