import Vue from 'vue'
import VueI18n from 'vue-i18n'
import messages from './translations/translation'

Vue.use(VueI18n)

// Create VueI18n instance with options
const i18n = new VueI18n({
  locale: 'no',
  messages
})

export { i18n }
