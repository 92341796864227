

/* Decorators */
import { Component, Vue } from 'vue-property-decorator'

/* Components */
import { Button as AppButton, Icon } from '@fagforbundet/components'

@Component({
  components: {
    Icon,
    AppButton
  }
})
export default class Index extends Vue {

}
